import React from 'react';
import { AlbumPhoto } from '../../firestore/firestore-types';
import "../../styles/album.css";
//icons

interface PolaroidProps {
    photo: AlbumPhoto;
    onDelete: (photo: AlbumPhoto) => void;
    onOpenModal: (photo:AlbumPhoto) => void;
}

const Polaroid: React.FC<PolaroidProps> = ({ photo, onDelete, onOpenModal }) => {
    const handleOpenModal = () => {
    onOpenModal(photo || '');
    };

    // const handleDelete = () => {
    // onDelete(photo);
    // };

    const getRandomPin = () => {
        const rotation = Math.floor(Math.random() * 21) - 10;
        const positionX = Math.floor(Math.random() * 41) - 20;
        const positionY = Math.floor(Math.random() * 25) + 10;
        // const offset = Math.floor(Math.random() * 20) - 50;
        return `rotate(${rotation}deg) translate(${positionX}px, ${positionY}px)`;
    }

    const getRandomTransform = () => {
        const rotation = Math.floor(Math.random() * 21) - 10;
        const positionX = Math.floor(Math.random() * 21) - 10;
        const positionY = Math.floor(Math.random() * 20) - 10;
        return `rotate(${rotation}deg) translate(${positionX}px, ${positionY}px)`;
    };

    return (
        <>
            
            <div className="polaroid" style={{ transform: getRandomTransform() }}>
                <img
                    style={{ transform: getRandomPin(), width: "2vh", height: "auto", zIndex: "101", position: "absolute", top: "0", 
                    left: "50%" , filter:"drop-shadow(5px 5px 5px #222)"}}
                    src="https://firebasestorage.googleapis.com/v0/b/hanimcilar-9f483.appspot.com/o/default%2Fimages%2FPin-PNG.png?alt=media&token=5061499d-ceaa-4178-8299-28ef01f2eab3"
                    alt="pin-random"
                />
                <img src={photo.url} alt="Polaroid Photo" onClick={handleOpenModal} />
                
                <div className="description">{photo.description}</div>
            </div>
        </>
    );
};

export default Polaroid;