import React, { useEffect , useState} from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import {User} from "firebase/auth";
import { AuthProvider, useAuth } from './contexts/authContext';
import AuthPage from './pages/auth';
import NotFoundPage from './pages/NotFoundPage';
import AlbumPage from './album/album';
import { get } from 'http';
import { addUser, getUser } from './firestore/db';
import { HanimcilarUser } from './firestore/firestore-types';
import { Timestamp } from 'firebase/firestore';
import AlbumsHub from './pages/AlbumsHub';
export interface AppProps {
  user : User | null;
}

function App(props: AppProps) {
  useEffect(() => {
    console.log("app mounted");
  }, [])
  const [userLoaded, setUserLoaded] = useState(false);
  var user = props.user;
  //if user doesn't exist in firestore, create it.
  useEffect(() => {
    if (user?.uid !== null && user !== null) {
      console.log("user is not null: ", user);
      getUser(user?.uid!).then((doc) => {
        if (doc !== null) {
          console.log("user exists in firestore");
          setUserLoaded(true);
        } else {
          console.log("user doesn't exist in firestore");
          var userObj: HanimcilarUser = {
            name: user?.displayName!,
            email: user?.email!,
            uid: user?.uid!,
            photoUrl: user?.photoURL!,
            paymentInfo: [],
            address: "",
            created: Timestamp.now(),
            albums: [],
            collaboratorAlbums: [],
          }
          addUser(userObj as HanimcilarUser).then((r) => {
            if (r === "success") {
              console.log("user added to firestore");
              setUserLoaded(true);
            } else {
              console.error("error adding user to firestore: ", r);
            }
          })
        
      }
      }).catch((err) => {
        console.error("error getting user from firestore: ", err);
      })
    } else {
      console.log("user is null");
      setUserLoaded(false);
    }
  } , [user])
  
  var host = window.location.host
  var subdomain = host.split('.')[0]
  var path = window.location.pathname;
  var albumOwner = path.split('/')[1];
  var albumId = path.split('/')[2];
  var albumIdDecoded = decodeURIComponent(albumId);
  if (subdomain === 'album' && user?.uid !== null && userLoaded) {
    console.log(user?.uid)
     return (
      <>
        <AuthProvider>
          <Routes>
            <Route path="/auth" element={<AuthPage user={props.user}/>} />
            <Route path="/" element={<AlbumsHub user={props.user}/>} />
            <Route path="/*" element={<AlbumPage user={props.user} albumOwner={albumOwner} albumId={albumIdDecoded}/>} />
          </Routes>
        </AuthProvider>
      </>
    );
  } else if (!userLoaded && subdomain === 'album' && user !== null) {
    console.log("user not loaded");
    return (
      <>
        <h1>LOADING</h1>
      </>
    );
  } else if (!userLoaded && subdomain !== 'album') {
    if (host.includes("localhost") || host.includes("127.0.0.1")) {
      return (
        <>
          <meta http-equiv="refresh" content="0; url=http://album.localhost:3001/" />
        </>
      );
    } else {
      return (
        <>
          <meta http-equiv="refresh" content="0; url=https://album.hanimcilar.com" />
        </>
      );
    }
  }
  else{
    console.log("else worked. user null, not loaded.");
    return (
      <>
        <AuthProvider>
          <Routes>
            <Route path="/*" element={<AuthPage user={props.user}/>} />
            {/* <Route path="/*" element={<NotFoundPage/>} /> */}
          </Routes> 
        </AuthProvider>
      </>
    );
  }
}

export default App;
