import { initializeFirestore , setDoc, doc , getDoc, getDocs, collection, addDoc, Timestamp, deleteDoc, where, query} from "firebase/firestore";
import { persistentLocalCache, persistentMultipleTabManager , CACHE_SIZE_UNLIMITED} from "firebase/firestore";
import { app } from "../configs/firebaseConfig";
import { AlbumPhoto, HanimcilarUser, Album } from "./firestore-types";
import { deleteAllFilesInDirectory, deleteFile } from "../storage/storage";
import { get } from "http";
const db = initializeFirestore(app, 
    {
        localCache: persistentLocalCache(
            {
                tabManager: persistentMultipleTabManager(),
                cacheSizeBytes: CACHE_SIZE_UNLIMITED,
            })
          
    }
);

export const addUser = async (userr: HanimcilarUser) => {
    try {
        const newUserDocId = userr.uid;
        const colRef = collection(db, "users");
        const docSnap = await getDocs(colRef);
        if (docSnap.docs.length > 0) {
            const data = docSnap.docs.map(doc => doc.data());
            const userExists = data.find((user) => user.uid === userr.uid);
            if (userExists) {
                console.log("db.ts 'addUser': user already existsin db");
                return "userexists";
            } else {
                var addedUserSuccessfully = false;
                //use setdoc with proper document id
                await setDoc(doc(db, "users", newUserDocId), userr).then((docRef) => {
                    console.log("Document written with ID: ", docRef);
                    addedUserSuccessfully = true;
                    return "added user to firestore";
                });
                if (addedUserSuccessfully) {
                    return "success";
                } else {
                    return "error";
                }
            }
        } else {
            //use setdoc with proper document id
            await setDoc(doc(db, "users", newUserDocId), userr).then((docRef) => {
                console.log("Document written with ID: ", docRef);
                return "added user to firestore";
            });
        }
    } catch (error) {
        console.error("Error adding document: ", error);
    }
}

export const getUser = async (uid: string) => {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        return docSnap.data() as HanimcilarUser;
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return null;
    }
}

export const addAlbumPhoto = async (uid: string, albumId:string, photoName: string, photoUrl: string, photoDescription: string, uploader:string, uploaderMail:string, uploaderPhotoUrl:string , storagePath?:string ,date?: string) => {
    //query albums collection to find album with document id albumId
    // if album exists, check owner and collaborators, if user is owner or collaborator, add photo to album
    // if album does not exist, return error
    try {
        const docRef = doc(db, "albums", albumId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const album = docSnap.data() as Album;
            if (album.owner === uid || album.collaborators.includes(uploaderMail)) {
                const photo: AlbumPhoto = {
                    name: photoName,
                    url: photoUrl,
                    description: photoDescription,
                    created: Timestamp.now(),
                    date: date ? date : "",
                    uploader: uploader,
                    uploaderPhotoUrl: uploaderPhotoUrl,
                    storagePath: storagePath ? storagePath: "",
                };
                album.photos.push(photo);
                await setDoc(docRef, album);
                return "success";
            } else {
                console.log("User is not owner or collaborator of this album!");
                return "notowner";
            }
        } else {
            console.log("No such album!");
            return "nosuchalbum";
        }
    } catch (error) {
        console.error("Error adding document: ", error);
    }
}

export const deleteAlbumPhoto = async (uid: string, albumId:string, photo:AlbumPhoto, email:string) => {
    //query albums collection to find album with document id albumId
    // if album exists, check owner and collaborators, if user is owner or collaborator, delete photo from album's photos array
    // if album does not exist, return error
    try {
        const docRef = doc(db, "albums", albumId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const album = docSnap.data() as Album;
            if (album.owner === uid || album.collaborators.includes(email)) {
                album.photos = album.photos.filter((photoInAlbum) => photoInAlbum.name !== photo.name || photoInAlbum.url !== photo.url);
                await setDoc(docRef, album);
                if (photo.storagePath)
                    deleteFile(photo.storagePath);
                return "success";
            } else {
                console.log("User is not owner or collaborator of this album!");
                return "notowner";
            }
        } else {
            console.log("No such album!");
            return "nosuchalbum";
        }
    } catch (error) {
        console.error("Error deleting document: ", error);
    }
}

export const getAlbumPhotos = async (uid: string, albumId: string, email:string) => {
    //check albums collection. if album exists with document id albumId, check owner, collaborators and sharedWith, if user is owner or collaborator or sharedWith, return photos
    // if album does not exist, return error
    try {
        const docRef = doc(db, "albums", albumId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const album = docSnap.data() as Album;
            if (album.owner === uid || album.collaborators.includes(email) || album.sharedWith.includes(email) || album.isPublic) {
                return album.photos;
            } else {
                console.log("User is not owner or collaborator of this album!");
                return "notowner";
            }
        } else {
            console.log("No such album!");
            return "nosuchalbum";
        }
    } catch (error) {
        console.error("Error getting album photos: ", error);
    }

}

export const updateAlbumPhoto = async (uid: string, mail:string ,albumId:string, photo:AlbumPhoto, email:string, photoName?: string, photoDescription?: string, date?: string) => {
    //query albums collection to find album with document id albumId
    // if album exists, check owner and collaborators, if user is owner or collaborator, update photo in album's photos array
    // if album does not exist, return error
    try {
        const docRef = doc(db, "albums", albumId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const album = docSnap.data() as Album;
            if (album.owner === uid || album.collaborators.includes(email)) {
                if (photoName !== undefined) {
                    photo.name = photoName;
                }
                if (photoDescription !== undefined) {
                    photo.description = photoDescription;
                }
                if (date !== undefined) {
                    photo.date = date;
                }
                album.photos = album.photos.filter((photoInAlbum) => photoInAlbum.name !== photo.name || photoInAlbum.url !== photo.url);
                album.photos.push(photo);
                await setDoc(docRef, album);
                return "success";
            } else {
                console.log("User is not owner or collaborator of this album!");
                return "notowner";
            }
        } else {
            console.log("No such album!");
            return "nosuchalbum";
        }
    } catch (error) {
        console.error("Error updating album photo: ", error);
    }
}

export const getUserAlbums = async (uid: string) => {
    try {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            const user = docSnap.data() as HanimcilarUser;
            const albums = user.albums;
            return albums;
        } else {
            console.log("No such user!");
            return null;
        }
    } catch (error) {
        console.error("Error adding document: ", error);
    }
}

//album id should be the same as the document Id of the album created in the "albums" collection.
export const addAlbum = async (uid: string, albumName: string, isPublic:Boolean, description?: string, photoUrl?:string, sharedWith?:string[] ,collaborators?: string[]) => {
    try {
        const docRef = doc(db, "users", uid);
        const collaboratorUids: string[] = [];
        collaborators?.forEach(async (collaborator) => {
            const colref = collection(db, "users");
            const q = query(colref, where("email", "==", collaborator));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                collaboratorUids.push(doc.data().uid);
                console.log("collaborator uid: ", doc.data().uid, "pushed to collaboratorUids array");
            });
        });
        // do this same thing but for collaborators, add it to their collaboratorAlbums array if their emails are in the collaborators array. (get their uids first)
        
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const user = docSnap.data() as HanimcilarUser;
            const album = user.albums.find((album) => album === albumName);
            if (album) {
                console.log("Album already exists!");
                return "albumexists";
            } else {
                const album: Album = {
                    albumId: albumName +"-"+uid,
                    owner: uid,
                    name: albumName,
                    photos: [],
                    collaborators: collaborators ? collaborators : [],
                    isPublic: isPublic,
                    sharedWith: sharedWith ? sharedWith : [],
                    photoUrl: photoUrl ? photoUrl : "https://firebasestorage.googleapis.com/v0/b/hanimcilar-9f483.appspot.com/o/default%2Fimages%2Fimage_2023-12-30_131116306.png?alt=media&token=952820c9-9cb3-4021-9465-675f46dfa770",
                    description: description ? description : "",
                };
                const albumRef = doc(db, "albums", album.albumId);
                await setDoc(albumRef, album);
                const albums = user.albums;
                albums.push(album.albumId);
                //add album to user's albums array, but dont clear the rest of the user data.
                await setDoc(docRef, { albums: albums }, { merge: true });
                if (collaboratorUids.length > 0) {
                    console.log("collaboratorUids: ", collaboratorUids);
                    for (const collaborator of collaboratorUids) {
                        const docRef = doc(db, "users", collaborator);
                        const docSnap = await getDoc(docRef);
                        if (docSnap.exists()) {
                            const user = docSnap.data() as HanimcilarUser;
                            const collaboratorAlbums = user.collaboratorAlbums;
                            const albumNew = albumName+"-"+uid;
                            collaboratorAlbums.push(albumNew);
                            console.log("collaboratorAlbums: ", collaboratorAlbums);
                            //add album to user's albums array, but dont clear the rest of the user data.
                            await setDoc(docRef, { collaboratorAlbums: collaboratorAlbums }, { merge: true }).then(() => {
                                console.log("added album to collaborator's collaboratorAlbums array");
                            });
                        } else {
                            console.log("No such user! (collaborator addalbum)");
                            return "nosuchuser";
                        }
                    }
                } else {
                    console.log("No collaborators to add album to their collaboratorAlbums array.");
                }
                return "success";
            }
        } else {
            console.log("No such user!");
            return "nosuchuser";
        }
    } catch (error) {
        console.error("Error adding document: ", error);
    }
}

export const deleteAlbum = async (uid: string, albumId: string, albumPath:string, albumCollaborators:string[]) => {
    //delete album from albums collection and from user's albums array
    try {

        for (const collaborator of albumCollaborators) {
            console.log("collaborator: ", collaborator);
            const colref = collection(db, "users");
            const q = query(colref, where("email", "==", collaborator));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (docs) => {
                const user = docs.data() as HanimcilarUser;
                const albumPathForCollaborator = "users/" + user.uid + "/albums/" + albumId + "/";
                console.log("albumPathForCollaborator: ", albumPathForCollaborator);
                await deleteAllFilesInDirectory(albumPathForCollaborator);
                //also remove albumphotos from album's photos array
                const albumRef = doc(db, "albums", albumId);
                const albumSnap = await getDoc(albumRef);
                if (albumSnap.exists()) {
                    const album = albumSnap.data() as Album;
                    const photos = album.photos.filter((photo) => photo.uploader !== user.email);
                    await setDoc(albumRef, { photos: photos }, { merge: true });
                    console.log("deleted albumphoto from album's photos array");
                } else {
                    console.log("No such album!");
                    return "nosuchalbum";
                }
                //delete album from collaborator's collaboratorAlbums array
                const collabUserRef = doc(db, "users", user.uid);
                const collaboratorAlbums = user.collaboratorAlbums.filter((album) => album !== albumId);
                console.log("collaboratorAlbums: ", collaboratorAlbums);
                await setDoc(collabUserRef, { collaboratorAlbums: collaboratorAlbums }, { merge: true }).then(() => {
                    console.log("deleted album from collaborator's collaboratorAlbums array");
                });
            });
        }
        // if user is owner of album, delete album from albums collection and from user's albums array. 
        // if user is collaborator, delete album from user's collaboratorAlbums array.
        // if user is neither, return error.
        //get collaborator users 

        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const user = docSnap.data() as HanimcilarUser;
            const album = user.albums.find((album) => album === albumId);
            if (album) {
                //user albumun sahibi ise
                const albumRef = doc(db, "albums", albumId);
                if (user.albums.includes(albumId)) {
                    const albums = user.albums.filter((album) => album !== albumId);
                    await deleteAllFilesInDirectory(albumPath);
                    await setDoc(docRef, { albums: albums }, { merge: true });
                    await deleteDoc(albumRef);
                    console.log("Album deleted successfully! : success-owner");
                    // if the array user.collaboratorAlbums includes albumId, delete albumId from user.collaboratorAlbums array and delete user's mail from album's collaborators array.
                    if (user.collaboratorAlbums.includes(albumId)) {
                        //user albumun collaboratoru ise
                        console.log("User is collaborator of this album, deleting from collaboratorAlbums array and deleting user's mail from album's collaborators array.");
                        user.collaboratorAlbums = user.collaboratorAlbums.filter((album) => album !== albumId);
                        await setDoc(docRef, user, {merge : true}).then(() => {
                            console.log("deleted album from collaborator's collaboratorAlbums array");
                        });
                        const albumRef = doc(db, "albums", albumId);
                        const albumSnap = await getDoc(albumRef);
                        if (albumSnap.exists()) {
                            const album = albumSnap.data() as Album;
                            const collaborators = album.collaborators.filter((collaborator) => collaborator !== user.email);
                            await setDoc(albumRef, { collaborators: collaborators }, { merge: true });
                            console.log("Album deleted successfully! : success-collaborator");
                            return "success-collaborator";
                        } else {
                            console.log("No such album!");
                            return "nosuchalbum";
                        }
                    } else console.log("User is not collaborator of this album.");
                    return "success-owner"
                } else {
                    if (user.collaboratorAlbums.includes(albumId)) {
                        //user albumun collaboratoru ise
                        console.log("User is collaborator of this album, deleting from collaboratorAlbums array and deleting user's mail from album's collaborators array.");
                        user.collaboratorAlbums = user.collaboratorAlbums.filter((album) => album !== albumId);
                        await setDoc(docRef, user, {merge : true}).then(() => {
                            console.log("deleted album from collaborator's collaboratorAlbums array");
                        });
                        const albumRef = doc(db, "albums", albumId);
                        const albumSnap = await getDoc(albumRef);
                        if (albumSnap.exists()) {
                            const album = albumSnap.data() as Album;
                            const collaborators = album.collaborators.filter((collaborator) => collaborator !== user.email);
                            await setDoc(albumRef, { collaborators: collaborators }, { merge: true });
                            console.log("Album deleted successfully! : success-collaborator");
                            return "success-collaborator";
                        } else {
                            console.log("No such album!");
                            return "nosuchalbum";
                        }
                    }
                    console.log("User is not owner of this album!");
                    return "notowner";
                }
            } else if (albumCollaborators.includes(user.email)) {
                console.log("User is collaborator of this album, deleting from collaboratorAlbums array and deleting user's mail from album's collaborators array.");
                const albumRef = doc(db, "albums", albumId);
                const albumSnap = await getDoc(albumRef);
                if (albumSnap.exists()) {
                    const album = albumSnap.data() as Album;
                    const collaborators = album.collaborators.filter((collaborator) => collaborator !== user.email);
                    await setDoc(albumRef, { collaborators: collaborators }, { merge: true });
                    console.log("Album deleted successfully! : success-collaborator");
                    return "success-collaborator";
                } else {
                    console.log("No such album!");
                    return "nosuchalbum";
                }
            } else {
                console.log("User is not owner of this album.");
                return "nosuchalbum";
            }
            
        } else {
            console.log("No such user!");
            return "nosuchuser";
        }
    } catch (error) {
        console.error("Error deleting document: ", error);
    }
}

export const getAlbum = async (uid: string, albumId: string, userMail:string) => {
    // only return the album if the user is the owner or collaborator or sharedWith
    try {
        const docRef = doc(db, "albums", albumId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const album = docSnap.data() as Album;
            if (album.owner === uid || album.collaborators.includes(userMail) || album.sharedWith.includes(userMail)) {
                return album;
            } else {
                console.log("User is not owner or collaborator of this album!");
                return "notowner";
            }
        } else {
            console.log("No such album!");
            return "nosuchalbum";
        }
    } catch (error) {
        console.error("Error adding document: ", error);
    }
}

export const updateAlbum = async (uid: string, mail:string ,albumId: string, albumName: string, isPublic?:Boolean, description?: string, photoUrl?:string, sharedWith?:string[] ,collaborators?: string[]) => {
    // only works if email is in the album's collaborators array or uid is the album's owner. get album, update its publicity, name, description, photoUrl, sharedWith and collaborators, then set the album again.
    //if someone is removed from collaborators, remove the album from their collaboratorAlbums array.
    try {
        const docRef = doc(db, "albums", albumId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const album = docSnap.data() as Album;
            if (album.owner === uid || album.collaborators.includes(mail)) {
                if (isPublic !== undefined) {
                    album.isPublic = isPublic;
                }
                if (albumName !== undefined) {
                    album.name = albumName;
                }
                if (description !== undefined) {
                    album.description = description;
                }
                if (photoUrl !== undefined) {
                    album.photoUrl = photoUrl;
                }
                if (sharedWith !== undefined) {
                    album.sharedWith = sharedWith;
                }
                if (collaborators !== undefined) {
                    album.collaborators = collaborators;
                }
                await setDoc(docRef, album);
                return "success";
            } else {
                console.log("User is not owner or collaborator of this album!");
                return "notowner";
            }
        } else {
            console.log("No such album!");
            return "nosuchalbum";
        }
    } catch (error) {
        console.error("Error updating album: ", error);
    }
}


export const getAlbumsById = async (uid: string,albumIds: string[], mail:string) => { //returns an array of Album objects from the albums collection.
    // only return the album if the user is the owner or collaborator or sharedWith
    try {
        const albums: Album[] = [];
        for (const albumId of albumIds) {
            const docRef = doc(db, "albums", albumId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const album = docSnap.data() as Album;
                if (album.owner === uid || album.collaborators.includes(mail) || album.sharedWith.includes(mail) || album.isPublic) {
                    albums.push(album);
                } else {
                    console.log("User is not owner or collaborator of this album!");
                    return "notownerorcollab";
                }
            } else {
                console.log("No such album!");
                return "nosuchalbum";
            }
        }
        return albums;
    } catch (error) {
        console.error("Error getting album by id ", error);
        return [];
    }
}

export const getCollaboratorAlbums = async (uid: string, mail:string) => {
    try {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const user = docSnap.data() as HanimcilarUser;
            const albums = user.collaboratorAlbums;
            return getAlbumsById(uid, albums, mail);
        } else {
            console.log("No such user!");
            return null;
        }
    } catch (error) {
        console.error("Error getting document: ", error);
    }
}

//profile pictures of collaborators to be displayed under the album name in the album page.
export const getCollaboratorProfilePictures = async (album: Album) => {
    // album.collaorators has email addresses, not uids. so we need to get the uids of the collaborators first.
    const collaboratorUids: string[] = [];
    // search for users with the email addresses in album.collaborators, get their uids and push them to collaboratorUids array.
    const colref = collection(db, "users");
    const q = query(colref, where("email", "in", album.collaborators));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        collaboratorUids.push(doc.data().uid);
    });
    // now we have the uids of the collaborators. we can get their profile pictures from the users collection.
    const collaboratorProfilePictures: string[] = [];
    for (const uid of collaboratorUids) {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const user = docSnap.data() as HanimcilarUser;
            collaboratorProfilePictures.push(user.photoUrl);
        } else {
            console.log("No such user!");
            return [];
        }
    }
    return collaboratorProfilePictures;
}

export const getAlbumOwnerInfo = async (album: Album) => {
    const docRef = doc(db, "users", album.owner);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const user = docSnap.data() as HanimcilarUser;
        return [user.name, user.photoUrl, user.uid, user.email];
    } else {
        console.log("No such user!");
        return null;
    }
}