import {useEffect, useState} from 'react';
import { Album } from '../../firestore/firestore-types';
import { getCollaboratorProfilePictures , getAlbumOwnerInfo} from '../../firestore/db';
import { User } from 'firebase/auth';

interface AlbumCardProps {
    user: User | null;
    album: Album;
    handleDeleteAlbum: (album: Album) => void;
}
const AlbumCard = (props: AlbumCardProps) => {
    const [albumOwnerInfo, setAlbumOwnerInfo] = useState<string[]>([]); // 0: name, 1: photoUrl, 2: uid, 3: email
    const [collaboratorProfilePictures, setCollaboratorProfilePictures] = useState<string[]>([]);
    const [albumOwnerPfp, setAlbumOwnerPfp] = useState<string | null>(null);
    useEffect(() => {
        // setCollaboratorProfilePictures([]); //reset collaborator profile pictures
        getAlbumOwnerInfo(props.album).then((ownerInfo) => {
            if (ownerInfo !== null) {
                setAlbumOwnerInfo(ownerInfo);
                setAlbumOwnerPfp(ownerInfo[1]);
            } else
            console.error("error getting album owner info");
        })

        console.log("album owner pfp: ", albumOwnerPfp);
        getCollaboratorProfilePictures(props.album).then((profilePictures) => {
            if (albumOwnerPfp !== null) {
                profilePictures.push(albumOwnerPfp);
                setCollaboratorProfilePictures(profilePictures);
            } else {
                console.error("error getting album owner profile picture");
            }
            if (profilePictures !== null)
            
            console.log("collaborator profile pictures: ", collaboratorProfilePictures);
        })
    }, [albumOwnerPfp])
let isPublicString = props.album.isPublic ? "Public" : "Private";
return (
    <div className="album-card">
        <div className="album-card-cover-photo">
            <a href={`/${props.album.owner}/${props.album.albumId}`}>
            <img style={{borderRadius:"50%", height:"100px", width:"100px", overflow:"hidden"}} src={props.album.photoUrl} alt="album cover"/>
            </a>
        </div>
        <a style={{textDecoration:"none", color:"inherit"}} href={`/${props.album.owner}/${props.album.albumId}`}>
        <div className="album-card-info">
            <div className="album-card-title">
                <h1>{props.album.name}<sub style={{fontSize:"1vw"}}>(Owner: {albumOwnerInfo[0]})</sub></h1>
            </div>
            <div className="album-card-description">
                <p>{props.album.description}</p>
            </div>
            <div className="album-card-collaborators">
                <p>Collaborators: </p>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignContent:"flex-start", marginLeft:"-14px"}}>
                {collaboratorProfilePictures && collaboratorProfilePictures.map((profilePicture, key) => {
                    console.log("profile picture: ", profilePicture);
                    return (
                        <>
                        <img key={key} style={{borderRadius:"50%", maxHeight:"40px", width:"40px", marginRight: '-14px',}} src={profilePicture} alt={`collaborator-pfp-${profilePicture}`}/>
                        {/* {profilePicture} */}
                        </>
                    )
                })}
                </div>
            </div>
            <div className="album-card-shared-with">
                {props.album.sharedWith.length > 0 ? <p>Shared With: </p> : <></>}
                {props.album.sharedWith.map((sharedWith) => {
                    return (
                        <>{sharedWith}, <br /></>
                    )
                })}
            </div>
            <div className="album-card-public-private">
                <p>{isPublicString}</p>
            </div>
        </div>
        </a>
        {props.album.owner === props.user?.uid ? <button onClick={()=>props.handleDeleteAlbum(props.album)}>Delete Album</button> : <button onClick={()=>props.handleDeleteAlbum(props.album)}>Delete Your Additions and Leave</button>}
        
    </div>
    )
}

export default AlbumCard;
