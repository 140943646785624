import { getDownloadURL, getStorage , ref, uploadBytes, deleteObject, listAll} from "firebase/storage";
import { app } from "../configs/firebaseConfig";

export const storage = getStorage(app);

// upload file with deisgnated name to storage
export const uploadFile = async (file: File, path: string, name:string) => {
    var result = "";
    const storageRef = ref(storage, path +"/"+ name);
    await uploadBytes(storageRef, file).then((snapshot) => {
        console.log('Uploaded a blob or file: ', snapshot.ref.fullPath);
        result = snapshot.ref.fullPath;
    }).catch((error) => {
        console.error("error uploading file: ", error);
    });
    const downloadUrlRef = ref(storage, result);
    return getDownloadURL(downloadUrlRef);
}

export const deleteFile = async (path: string) => {
    const storageRef = ref(storage, path+"/");
    await deleteObject(storageRef).then(() => {
        console.log("file deleted");
    }).catch((error) => {
        console.error("error deleting file: ", error);
    });
}

export const deleteAllFilesInDirectory = async (directorypath: string) => {
    console.log("deleting all files in directory: ", directorypath);
    //get all files in the directory, save their paths as refs and delete them. also delete every subdirectory and their subdirectories etc... and their files.
    const storageRef = ref(storage, directorypath);
    const listResult = await listAll(storageRef);
    listResult.items.forEach(async (itemRef) => {
        await deleteFile(itemRef.fullPath);
        console.log("deleted file: ", itemRef.fullPath);
    });
    listResult.prefixes.forEach(async (prefixRef) => {
        await deleteAllFilesInDirectory(prefixRef.fullPath);
        console.log("deleted directory: ", prefixRef.fullPath);
    });

}