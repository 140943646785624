import { useEffect, useState } from 'react';
import { User } from 'firebase/auth';
import { AuthProvider } from '../contexts/authContext';
import { Route, Routes } from 'react-router-dom';
import AuthPage from '../pages/auth';
import { addAlbumPhoto, getAlbumPhotos } from '../firestore/db';
import useDrivePicker from 'react-google-drive-picker'
import { CallbackDoc, PickerCallback } from 'react-google-drive-picker/dist/typeDefs';
import {getAlbum, deleteAlbumPhoto} from '../firestore/db';
import { Album, AlbumPhoto } from '../firestore/firestore-types';
import { uploadFile } from '../storage/storage';
import { Timestamp } from 'firebase/firestore';
import Polaroid from '../pages/components/polaroid';
//styles
import "../styles/album.css";
//icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';

interface albumProps {
    user: User | null;
    albumOwner: string; //uid
    albumId: string; //doc id of album, id field equals this
}

const AlbumPage = (props: albumProps) => {

const [userCanAccessAlbum, setUserCanAccessAlbum] = useState(false);
const [openPicker, authResponse] = useDrivePicker();  
const [data, setData] = useState<CallbackDoc[] | null>(null);
const [album, setAlbum] = useState<Album | null>(null); //album object from firestore
var timestamp: Timestamp;
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
    const handleOpenPicker = () => {
        openPicker({
        clientId: "89038291387-n2aos6dtahid3b0ai9ds0uhqbqrtss91.apps.googleusercontent.com",
        developerKey: "AIzaSyD8hAEGTpxTyP2bdRvj95CRkHr4vap4HT8",
        viewId: "DOCS",
        token: authResponse?.access_token,
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        multiselect: true,
        // customViews: customViewsArray, // custom view
        callbackFunction: (data) => {
            if (data.action === 'cancel') {
            console.log('drive : User clicked cancel/close button')
            }
            if (data.action === 'loaded') {
            console.log('drive : Picker loaded successfully')
            }
            if (data.action === 'authed') {
            console.log('drive : User authenticated successfully')
            }
            if (data.action === 'picked') {
            console.log('drive : User selected files/folders', data.docs)
            setData(data.docs)
            }
            
        },
        
        })
    }

    useEffect(() => {
        if (props.user !== null && props.albumId !== null) {
            console.log("user and album id is not null: ", props.user?.uid, props.albumId);
        getAlbum(props.albumOwner, props.albumId, props.user.email!).then((doc) => {
            if (doc !== undefined && doc !== "nosuchalbum" && doc !== "notowner") {
                setAlbum(doc);
                console.log("album set: ", album);
            } else if (doc === "nosuchalbum") {
                console.log("no such album");
                setAlbum(null);
            } else if (doc === "notowner") {
                console.log("not owner");
                setAlbum(null);
            } else if (doc === undefined){
                console.log("doc is undefined");
                setAlbum(null);
            }
        })
        } else {
            console.log("user or album id is null");
        }
    } , [props.user, props.albumId, props.albumOwner])

    useEffect(() => {
        console.log(props.user?.email!)
        getAlbumPhotos(props.user?.uid!, props.albumId, props.user?.email!).then((docs) => {
            console.log("getAlbumPhotos : ",docs);
            if (docs && docs !== undefined && docs!== "nosuchalbum" && docs !== "notowner" && docs.length > 0){
                setAlbumPhotos(docs);
            } else if (docs === "nosuchalbum") {
                console.log("no such album");
                setAlbumPhotos([]);
            } else if (docs === "notowner") {
                console.log("not owner");
                setAlbumPhotos([]);
            } else {
                console.log("no photos in album");
                setAlbumPhotos([]);
            }

        })
        console.log("album photos: ", albumPhotos);
    }, [props.user, props.albumId])

    useEffect(() => {
        // check if user can access album by checking if user is in album's collaborators array, sharedwith array , is owner or if album is public
        if (props.user !== null) {
            if (props.albumOwner === props.user.uid) {
                setUserCanAccessAlbum(true);
                console.log("user is owner");
            } else {
                if (album !== null) {
                    if (album.collaborators.includes(props.user.email!)) {
                        setUserCanAccessAlbum(true);
                        console.log("user is a collaborator");
                    } else if (album.sharedWith.includes(props.user.email!)) {
                        setUserCanAccessAlbum(true);
                        console.log("album is shared with user");
                    } else if (album.isPublic) {
                        setUserCanAccessAlbum(true);
                        console.log("album is public");
                    } else {
                        setUserCanAccessAlbum(false);
                        console.log("user cannot access album");
                    }
                } else {
                    setUserCanAccessAlbum(false);
                    console.log("user cannot access album because album is null");
                }
            }
        } else {
            console.log("user is null");
            setUserCanAccessAlbum(false);
            console.log("user cannot access album");
        }

    }, [album, props.user, props.albumOwner])

    useEffect(() => {
        if (props.user !== null) {
        console.log("data: ", data)
            if (data !== null) {
                console.log("data is not null");
                data.forEach((doc) => {
                    addAlbumPhoto(props.user!.uid, albumId, doc.name, doc.url.replace("view", "preview"), "", props.user?.displayName!, props.user?.email!, props.user?.photoURL!, undefined,"$optional_date_here").then((r) => {
                        if (r === "nosuchalbum") {
                            console.log("no such album");
                        } else if (r === "notowner") {
                            console.log("not owner");
                        } else if (r === "success") {
                        console.log(`file ${doc.name} added to album ${albumId}`);
                        var oldAlbumPhotos = albumPhotos;
                        var newAlbumPhotos = [...oldAlbumPhotos, {
                            name: doc.name,
                            url: doc.url.replace("view", "preview"),
                            description: "",
                            created: Timestamp.now(),
                            date: "",
                            uploader : props.user?.displayName!,
                            uploaderPhotoUrl: props.user?.photoURL!,
                            storagePath: undefined,
                        }];
                        setAlbumPhotos(newAlbumPhotos);
                        }
                    })
                })
            } else {
                console.log("data is null");
            }
        } else {
            console.log("user is null");
        }
    }, [data, props.user, props.albumId])
    
    //parse url, get directory
    //get album name from directory

    const [albumId, setAlbumId] = useState("");
    const directory = window.location.pathname;
    const albumIdFromDirectory = directory.split('/')[2];
    console.log("album id from directory: ", albumIdFromDirectory);
    const [albumPhotos, setAlbumPhotos] = useState<AlbumPhoto[]>([]);

    useEffect(() => {
        setData(null);
        console.log("Page loaded, set data to null. data: ", data);
        setAlbumId(props.albumId);
        if (props.user !== null) {
            getAlbumPhotos(props.user?.uid, albumId, props.user?.email!).then((docs) => {
                console.log(docs);
            })
        }
    }, [])

    //UPLOAD FILE FROM DEVICE TO FIREBASE STORAGE, firestore and adds to album
    const [files, setFiles] = useState<File[] | null>(null);
    // user will pick files, then they will be iterated through and uşloaded with the hel pof AddAlbumPhoto function
    useEffect(() => {
        setFiles(null)
        console.log("Page loaded, set files(upload) to null. files: ", files);
    } , [])
    
    useEffect(() => {
        if (files !== null) {
            console.log("files: ", files);
            files.forEach((file) => {
                console.log("file: ", file);
                timestamp = Timestamp.now();
                const uploadPath = "users/"+props.user!.uid+"/albums/"+albumId+"/photos/"
                const fileName = file.name+timestamp;
                uploadFile(file, uploadPath,fileName).then((url) => {

                    console.log("url: ", url);
                    if (url !== null) {
                        console.log("url is not null: " , url);
                        addAlbumPhoto(props.user!.uid, albumId, file.name, url, "", props.user?.displayName!, props.user?.email!, props.user?.photoURL!, uploadPath+"/"+fileName,"$optional_date_here").then((r) => {
                            if (r === "nosuchalbum") {
                                console.log("no such album");
                            } else if (r === "notowner") {
                                console.log("not owner");
                            } else if (r === "success") {
                            console.log(`file ${file.name} added to album ${albumId}`);
                            var oldAlbumPhotos = albumPhotos;
                            setAlbumPhotos([...oldAlbumPhotos, {
                                name: file.name,
                                url: url,
                                description: "",
                                created: timestamp,
                                date: "",
                                uploader : props.user?.displayName!,
                                uploaderPhotoUrl: props.user?.photoURL!,
                                storagePath: uploadPath+"/"+fileName,
                            }]);
                            }
                        })
                    } else {
                        console.log("url is null");
                    }
                })
            })
            
            setFiles(null);
        }
    }, [files, props.user, albumId])

    const handleDeletePhoto = (photo: AlbumPhoto) => {
        deleteAlbumPhoto(props.user!.uid, albumId, photo, props.user?.email!).then((r) => {
            if (r === "nosuchalbum") {
                console.log("no such album");
            } else if (r === "notowner") {
                console.log("not owner");
            } else if (r === "success") {
            console.log(`file ${photo.name} deleted from album ${albumId}`);
            setAlbumPhotos(albumPhotos.filter((p) => p.url !== photo.url));
            }
        }
        )
    }

    //kopyamakarna gpt
    

    if (props.user !== null) {
        if (album !== null && (userCanAccessAlbum)) {
        return (
            <>
                <div className='album-page-wrapper'>
                    <div className='top-bar'>
                        <div className='nav-go-back'>
                            <a href="/"><ArrowBackIcon/></a>
                        </div>
                        <div className='album-cover-photo'>
                            <img src={album.photoUrl} alt="album cover"/>
                        </div>
                        <div className='album-info'>
                            <div className='album-name-and-description'>
                                <p><b>{album.name}</b></p>
                                <p>{album.description}</p>
                            </div>
                        <div className='album-settings'>
                            <SettingsIcon/>
                        </div>
                        </div>
                    </div>
                    
                    <div className="add-image-buttons">
                        <div className="add-image-buttons-drive" style={{display:"flex", flexDirection:"row"}} onClick={() => handleOpenPicker()}>
                            Add Media from Drive 
                            <img 
                            style={{width: "30%", height: "auto"}}
                            src="https://firebasestorage.googleapis.com/v0/b/hanimcilar-9f483.appspot.com/o/default%2Fimages%2Fdrive-icon.png?alt=media&token=addf83cc-11d3-4bdf-b3a8-7d4ece4b9fd0" alt="add-from-drive-png" 
                            />
                        </div>
                        
                        <div className='file-input-label'>
                            Add Media from Gallery <br />
                            <label className="file-input-label" htmlFor="fileinput" content='Add Media from Gallery'>
                                <img 
                                style={{width: "30%", height: "auto"}}
                                src="https://firebasestorage.googleapis.com/v0/b/hanimcilar-9f483.appspot.com/o/default%2Fimages%2Fadd-image.png?alt=media&token=b413954c-ddc1-496c-8c11-bf237cd9eeb5" alt="add-file-png" />
                            </label>
                            <input id="fileinput"type="file" accept="image/*" multiple onChange={
                                (e) => {
                                    if (e.target.files) {
                                        setFiles(Array.from(e.target.files));
                                    }
                                }
                            } />
                        </div>
                    </div>
                    {/* Bu da bi fikirdi ama ii durmuyo */}
                    {/* <img 
                        src='https://firebasestorage.googleapis.com/v0/b/hanimcilar-9f483.appspot.com/o/default%2Fimages%2FTorrnNotebookPaper.png?alt=media&token=981b50aa-b1a1-49e5-8ba7-5bc5bb8b6cea' 
                        alt="notepadpng"
                        style={{width: "40%", height: "40%"}} // put it at the top of the bulletin board in the middle, theres gonna be a text in it, centered and middle.
                    /> */}

                    <div className="bulletin-board" id="bulletinBoard">
                        
                        {/* gpt kopyamakarna */}
                        {albumPhotos.map((photo) => (
                            <>
                                <div className="modal" id={`modal-${photo.name+photo.created}`}>
                                    <div className="modal-content">
                                        <span className="close-btn" onClick={() => {document.getElementById(`modal-${photo.name+photo.created}`)!.style.display = "none"}}>&times;</span>
                                        <img src={photo.url}id="modalImage" alt="Enlarged Polaroid"/>
            
                                        {/* <iframe src="" id="modalImage" title='asd'></iframe> */}
                                        <div className='name-and-edit-flex'>
                                            <h2 className='modal-h2'>{photo.name}</h2> 
                                            <img style={{height:"calc(10px + 3vmin)", width:"auto", marginLeft:"2vw", marginTop:"1vh", cursor:"pointer"}} onClick={() => {
                                                console.log("edit photo clicked");
                                            }}
                                            src="https://firebasestorage.googleapis.com/v0/b/hanimcilar-9f483.appspot.com/o/default%2Fimages%2Fedit-icon-png-3602.png?alt=media&token=dffb7aa1-4f9d-4e99-93b1-7e75d5cbb7a6" alt="editphotoicon"/>
                                        </div>
                                        
                                        <div id="modalDescription" className="modal-description">
                                            {((photo.description && photo.description !== "") && <p>{photo.description}</p>)}
                                            {(photo.description === "" && <p>no description</p>)}
                                        </div>
                                        <div className='deletephoto'>
                                            <button style={{ display: 'contents', cursor:"pointer"}} onClick={()=>handleDeletePhoto(photo)}>
                                                <DeleteIcon style={{color:"red", width:"4vh", height:"auto"}}/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <Polaroid
                                    key={photo.url} // Ensure each polaroid has a unique key
                                    photo={photo}
                                    onDelete={handleDeletePhoto}
                                    onOpenModal={(photo) => {
                                    document.getElementById(`modal-${photo.name+photo.created}`)!.style.display = 'flex';
                                    }}
                                />
                            </>
                        ))}

                        {/* react map function will dynamically add polaroid elements here */}

                        {/* {albumPhotos.map((photo) => {
                            if (!photo.url.includes("drive.google.com")) {
                                console.log("photo url doesnt include drive.google.com");
                            return (
                                <>
                                <div className="polaroid" id="polaroid" 
                               
                                >
                                    <img src={photo.url} alt="polaroid"  onClick={() => {
                                    document.getElementById("modal")!.style.display = "block";
                                    document.getElementById("modalImage")!.setAttribute("src", photo.url);
                                    document.getElementById("modalDescription")!.innerHTML = photo.description!;
                                }}/>
                                    <div className="description" id="description">{photo.description}</div>
                                    <div>
                                        <button style={{display:"contents"}} onClick={() => handleDeletePhoto(photo)}>X</button>
                                    </div>
                                </div>
                                
                                </>
                            )} else {
                                console.log("photo url includes drive.google.com");
                                return (
                                    <>
                                    <div className="polaroid" id="polaroid" 
                                    >   
                                        <div onClick={() => {
                                            document.getElementById("modal")!.style.display = "block";
                                            document.getElementById("modalImage")!.setAttribute("src", photo.url);
                                            document.getElementById("modalDescription")!.innerHTML = photo.description!;
                                        }}>
                                        <iframe title='drive-preview-iframe' src={photo.url} />
                                        </div>
                                        <div className="description" id="description">{photo.description}</div>
                                        <div>
                                            <button style={{display:"contents"}} onClick={() => handleDeletePhoto(photo)}>X</button>
                                        </div>
                                    </div>
                                    
                                    </>
                                )
                            }
                        }
                        )} */}

                        
                        {/* <div className="polaroid" id="polaroid">
                            <img src="" />
                            <div className="description" id="description"></div>
                        </div> */}
                    </div>
                </div>
            </>
        ) } else return (
            <div> album doesnt exist</div>
        )
    } else return (
        <>
            <h1>NOT LOGGED IN</h1>
            <AuthProvider>
                <AuthPage user={props.user} />
            </AuthProvider>
        </>
    )
}

export default AlbumPage;