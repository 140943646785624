
import { User, signOut, onAuthStateChanged, Auth} from "firebase/auth";
import { Route, Routes } from 'react-router-dom';
import { auth, useAuth } from '../contexts/authContext';
import { useState, useContext, useEffect, createContext, ReactNode} from "react";
import { createUserWithEmailAndPassword, getAuth, GoogleAuthProvider, signInWithPopup ,signInWithEmailAndPassword } from "firebase/auth";
import { app } from "../configs/firebaseConfig";
import { addUser } from "../firestore/db";
import { HanimcilarUser } from "../firestore/firestore-types";
import { CollectionReference, Timestamp } from "firebase/firestore";
import { redirect } from "react-router-dom";
//styles
import "../styles/authpage.css";
export const logOut = async () => {
  await signOut(auth)
}

const IDontCareAboutFirebaseAuth = () => {
  var user = useAuth();
  return (
    <>
    <p className="idontcare-p"> {user ? "Logged in as :"+user?.displayName : "Not logged in."}</p>
    <button className="logout-button" onClick={() => {
      logOut().then(()=>{
        console.log("logged out of account:", user?.displayName);
        user = null;
      })
      }}>log out</button>
    </>
  );
};
interface AuthPageProps {
    user: User | null;
  }
const AuthPage = (props: AuthPageProps) => {
    const user = useAuth();

    return (
            <div className="signup-page-wrapper">
              <IDontCareAboutFirebaseAuth/>
                {/* sign up with google */}
                <button className="signin-google-button" onClick={() => {
                    const provider = new GoogleAuthProvider();
                    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
                    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
                    provider.addScope('https://www.googleapis.com/auth/drive');
                    provider.addScope('openid');
                    signInWithPopup(auth, provider).then(async (result) => {
                        const credential = GoogleAuthProvider.credentialFromResult(result);
                        const token = credential?.accessToken;
                        const user = result.user;
                        const timestampnow = Timestamp.now();
                        console.log(user);
                        console.log(user.email, user.uid, user.displayName);
                        console.log(token);
                        //add user to firestore users collection
                        const hanimcilarUser: HanimcilarUser = {
                            email: user.email!,
                            name: user.displayName!,
                            uid: user.uid,
                            paymentInfo: [],
                            photoUrl: "",
                            address: "",
                            created: Timestamp.now(),
                            albums: [],
                            collaboratorAlbums: [],
                        }
                        await addUser(hanimcilarUser).then((docRefId) => {
                            console.log("user added to firestore users collection with id: ", docRefId);
                        
                        });
                    }).catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        const email = error.email;
                        const credential = GoogleAuthProvider.credentialFromError(error);
                        console.log("error", errorCode);
                        console.log("error",errorMessage);
                        console.log(email);
                        console.log(credential);
                    });
                }}>sign up with google</button>
            </div>
    );
};

export default AuthPage;