import { Timestamp } from "firebase/firestore";

export class HanimcilarUser {
    email: string;
    name: string;
    uid: string;
    paymentInfo: string[];
    created : Timestamp;
    photoUrl: string;
    address: string;
    albums: string[];
    collaboratorAlbums: string[];
    constructor(email: string, name: string, uid: string, paymentInfo: string[], photoUrl: string, address: string, albums: string[], collaboratorAlbums: string[]) {
        this.email = email;
        this.name = name;
        this.uid = uid;
        this.paymentInfo = paymentInfo;
        this.created = Timestamp.now();
        this.photoUrl = photoUrl;
        this.address = address;
        this.albums = albums;
        this.collaboratorAlbums = collaboratorAlbums;
    }
}

export class AlbumPhoto {
    name: string;
    url: string;
    description?: string;
    created: Timestamp;
    date?: string;
    uploader : string;
    uploaderPhotoUrl: string;
    storagePath?: string;
    constructor(name: string, url: string,uploader:string, uploaderPhotoUrl:string , storagePath?:string ,description?: string, date?: string) {
        this.name = name;
        this.url = url;
        this.description = description;
        this.created = Timestamp.now();
        this.date = date;
        this.uploader = uploader;
        this.uploaderPhotoUrl = uploaderPhotoUrl;
        this.storagePath = storagePath;
    }
}

export class Album {
    albumId: string;
    owner: string; //uuid of owner
    collaborators: string[]; //uuids of colaborators
    name: string;
    photos: AlbumPhoto[];
    sharedWith: string[]; //emails of people shared with
    isPublic: Boolean;
    photoUrl?: string;
    description?: string;
    constructor(name: string, owner: string , photos: AlbumPhoto[], collaborators: string[], isPublic: boolean, sharedWith: string[], albumId: string, photoUrl?: string, description?: string) {
        this.name = name;
        this.owner = owner;
        this.photos = photos;
        this.collaborators = collaborators;
        this.isPublic = isPublic;
        this.sharedWith = sharedWith;
        this.albumId = albumId;
        this.photoUrl = photoUrl;
        this.description = description;
    }
}