import {useEffect, useState} from "react";
import { User } from "firebase/auth";
import "../styles/albumshub.css"
import { addAlbum, deleteAlbum, getAlbumsById, getCollaboratorAlbums, getUser, getUserAlbums } from "../firestore/db";
import { uploadFile } from "../storage/storage";
import { getDownloadURL , ref } from "firebase/storage";
import { storage } from "../storage/storage";
import { Album, HanimcilarUser } from "../firestore/firestore-types";
import { AuthProvider } from "../contexts/authContext";
import { logOut } from "./auth";
import AuthPage from "./auth";
import AlbumCard from "./components/albumCard";
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import DeleteIcon from '@mui/icons-material/Delete';
interface AlbumsHubProps {
    user: User | null;
}
const AlbumsHub = (props: AlbumsHubProps) => {
    
    const user = props.user;
    const [hanimcilarUser, setHanimcilarUser] = useState<HanimcilarUser | null>(null);
    const [albumIds, setAlbumIds] = useState<string[] | undefined>([]);
    const [albums, setAlbums] = useState<Album[]>([]); //array of album objects
    const [collabAlbums, setCollabAlbums] = useState<Album[]>([]); //array of album objects
    const [albumsFinal, setAlbumsFinal] = useState<Album[]>([]); //array of album objects FINAL collab + non collab
    const [newAlbumName, setNewAlbumName] = useState("New Album"+ albumIds?.length);
    const [newAlbumDescription, setNewAlbumDescription] = useState("");
    const [newAlbumCoverPhoto, setNewAlbumCoverPhoto] = useState<string | undefined>(undefined);
    const [newAlbumCollaborators, setNewAlbumCollaborators] = useState<string[]>([]);
    const [newAlbumSharedWith, setNewAlbumSharedWith] = useState<string[]>([]);
    const [newAlbumPublic, setNewAlbumPublic] = useState(false);


    useEffect(() => {
        console.log("albums hub mounted");
        if (user !== null) {
            getUser(user?.uid!).then((doc) => {
                if (doc !== null) {
                    setHanimcilarUser(doc);
                    console.log("hanimcilar user acquired: ", HanimcilarUser);
                } else {
                    console.error("error getting user from firestore");
                }
            })
        }
        getAlbumsIds();
        setNewAlbumName("")
        setNewAlbumDescription("")
        setNewAlbumCoverPhoto(undefined)
        setNewAlbumCollaborators([])
        setNewAlbumSharedWith([])
        setNewAlbumPublic(false)
        if (albumIds?.length! >= 1) {
            getAlbumsById(props.user?.uid!,albumIds!, props.user?.email!).then((docs) => {
                console.log(docs);
                if (docs !== "nosuchalbum" && docs !== "notownerorcollab") {
                    setAlbums(docs);
                }
            } )
        } else {
            console.log("no album ids found");
        }
        getCollaboratorAlbums(user?.uid!, user?.email!).then((docs) => {
            console.log("collaborator Albums : ",docs);
            
            if (docs !== "nosuchalbum" && docs !== "notownerorcollab" && docs !== null && docs !== undefined) {
            setCollabAlbums(docs);
            }console.log("collabAlbums :",collabAlbums);
        } )
    }, [user])

    useEffect(() => {
        if (albumIds?.length! >= 1) {
            getAlbumsById(props.user?.uid! ,albumIds!, props.user?.email!).then((docs) => {
                
                if (docs !== "nosuchalbum" && docs !== "notownerorcollab") setAlbums(docs);console.log("Albums :",albums);
            } )
        } else {
            console.log("no album ids found");
            // setAlbums([]);
        }
    } , [albumIds])

    const getAlbumsIds = () => {
        console.log("get albums");
        getUserAlbums(user?.uid!).then((Ids) => {
            if (Ids === null) {
                console.log("no albums found");
                setAlbumIds([]);
                return;
            }
            console.log(Ids);
            setAlbumIds(Ids); // returns array of album ids only.
        })
    }
    const handleAddAlbum = () => {
        console.log("add album");
        if (newAlbumName === "") {
            console.log("album name cannot be empty");
            alert("Album name cannot be empty");
            return;
        }

        addAlbum(user?.uid!, newAlbumName, newAlbumPublic, newAlbumDescription, newAlbumCoverPhoto, newAlbumSharedWith, newAlbumCollaborators).then((s) => {
            if (s === "success") {
                console.log("album added :", newAlbumName);
                setNewAlbumName("")
                setNewAlbumDescription("")
                setNewAlbumCoverPhoto(undefined)
                setNewAlbumCollaborators([])
                setNewAlbumSharedWith([])
                setNewAlbumPublic(false)
                toggleAddAlbumWindow();
                getAlbumsIds();
            }
        })
    }

    const handleDeleteAlbum = (album:Album) => {
        console.log("delete album ", album.albumId);
        //delete album from firestore albums collection and from user's albums array
        const albumPath = "users/"+ album.owner+ "/albums/"+ album.name+"-"+user?.uid;
        deleteAlbum(user?.uid!, album.albumId, albumPath, album.collaborators).then((s) => {
            if (s === "success-owner") {
                console.log("album deleted globally (owner):", album.albumId);
                setAlbumsFinal(albumsFinal.filter((a) => a.albumId !== album.albumId));
            } else if (s === "success-collaborator") {
                console.log("album deleted from user's and album's collab array (collab):", album.albumId);
                setAlbumsFinal(albumsFinal.filter((a) => a.albumId !== album.albumId));
            } else {
                console.error("error deleting album: ", s);
            }
        })
    }

    const [addAlbumWindow, setAddAlbumWindow] = useState(false);
    const toggleAddAlbumWindow = () => {
        //reset all fields
        setNewAlbumName("")
        setNewAlbumDescription("")
        setNewAlbumCoverPhoto(undefined)
        setNewAlbumCollaborators([])
        setNewAlbumSharedWith([])
        setNewAlbumPublic(false)
        //toggle visibility of add album window
        setAddAlbumWindow(!addAlbumWindow);
    }

    useEffect(() => {
        if (albums !== null && collabAlbums !== null)
            setAlbumsFinal(albums.concat(collabAlbums));
        console.log("albums final: ", albumsFinal);
        
    } , [albums, collabAlbums, setAlbumsFinal])

    if (props.user !== null) {
        return (
        <>  
            <div className="albums-hub-wrapper">
                <div className='top-bar'>
                    
                    <div className='album-cover-photo'>
                        <img src={hanimcilarUser?.photoUrl} alt="album cover"/>
                    </div>
                    <div className='album-info'>
                        <div className='albumshub-settings'>
                            <SettingsIcon/>
                        </div>
                        <div style={{margin:"auto"}}className='albumshub-username'>
                            <h1>{hanimcilarUser?.name}'s Albums</h1>
                        </div>
                    </div>
                    
                    <div className='nav-logout-albumshub-auth' onClick={logOut}>
                        <a href="/"><LogoutIcon/></a>
                    </div>
                </div>
                <div className="album-cards-list">
                    <div className="add-album-card" onClick={toggleAddAlbumWindow}> 
                        <div className="add-album-card-plus-sign">
                            <h1>+</h1>
                        </div>
                        <div className="add-album-card-info">
                            <h1>Add New Album</h1>
                        </div>
                    </div>
                    {/* now create the add album div, which is a bunch of input fields */}
                        <div className= {`add-album-popup ${addAlbumWindow ? 'visible' : ''}`}>
                            <div className="add-album-popup-content">
                                <div className="add-album-popup-header">
                                    <h1 style={{color:"lightgrey"}}>Add New Album</h1>
                                </div>
                                <div className="add-album-popup-body">
                                    <div className="add-album-popup-body-inputs">
                                        <div className="add-album-popup-body-inputs-album-name">
                                            <input type="text" placeholder="Album Name" required value={newAlbumName}
                                            onChange={
                                                (e) => {
                                                    setNewAlbumName(e.target.value);
                                                }
                                            }/>
                                        </div>
                                        <div className="add-album-popup-body-inputs-album-description">
                                            <input type="text" placeholder="Album Description" value={newAlbumDescription}
                                            onChange={
                                                (e) => {
                                                    setNewAlbumDescription(e.target.value);
                                                }
                                            
                                            }/>
                                        </div>
                                        {newAlbumName && 
                                            <div className="add-album-popup-body-inputs-album-cover-photo">
                                                <input type="file" placeholder="Album Cover Photo" 
                                                onChange={
                                                    (e) => {
                                                        const fileUploadAlbumName = newAlbumName+"-"+user?.uid;
                                                        uploadFile(e.target.files![0], "users/"+ user?.uid!+ "/albums/"+ fileUploadAlbumName, "AlbumCover").then((pathUploaded) => {
                                                        const storageRef = ref(storage, pathUploaded);
                                                        getDownloadURL(storageRef).then((url) => {
                                                            url && setNewAlbumCoverPhoto(url);
                                                        })
                                                        
                                                    } )
                                                    console.log(newAlbumCoverPhoto)
                                                }}/>
                                                {newAlbumCoverPhoto &&
                                                    <img src={newAlbumCoverPhoto} alt={`album-cover-${newAlbumName}`} style={{borderRadius:"50%", width:"50", height:"50", overflow:"clip"}}/>
                                                }
                                                
                                            </div>
                                        }
                                        
                                        <div className="add-album-popup-body-inputs-album-collaborators">
                                            <input type="text" placeholder="Collaborators" value={newAlbumCollaborators}
                                            onChange={
                                                (e) => {
                                                    setNewAlbumCollaborators(e.target.value.split(','));
                                                }
                                            
                                            }/>
                                        </div>
                                        <div className="add-album-popup-body-inputs-album-shared-with">
                                            <input type="text" placeholder="Shared With (email, seperate with commas)" value={newAlbumSharedWith}
                                            onChange={
                                                (e) => {
                                                    setNewAlbumSharedWith(e.target.value.split(','));
                                                }
                                            
                                            }/>
                                        </div>
                                        <div className="add-album-popup-body-inputs-album-public-private">
                                            <label htmlFor="public-checkbox">Public :</label>
                                            <input type="checkbox" id="public-checkbox" placeholder="Public/Private" value={newAlbumPublic.toString()}
                                            onChange={
                                                (e) => {
                                                    setNewAlbumPublic(!e.target.checked);
                                                    console.log(newAlbumPublic);
                                                }
                                                
                                            }/>
                                        </div>
                                    </div>
                                    <div className="add-album-popup-body-buttons">
                                        <div className="add-album-popup-body-buttons-cancel">
                                            <button onClick={toggleAddAlbumWindow}>Cancel</button>
                                        </div>
                                        <div className="add-album-popup-body-buttons-submit">
                                            <button onClick={handleAddAlbum}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* now create the album cards */}
                    {albumsFinal && albumsFinal?.map((album, key) => {
                        return (
                            <AlbumCard key={key} album={album} handleDeleteAlbum={handleDeleteAlbum} user={user}/>
                        )
                    })}
                    
                </div>
            </div>
            
        </>
    );
    } else { 
        return (
            <>
                <div className="albums-hub-wrapper">
                    Albums Hub
                    Not logged in.
                    <AuthProvider>
                        <AuthPage user={props.user} />
                    </AuthProvider>
                </div>
                
            </>
        );
    }
}

export default AlbumsHub;