// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCgsAG3CovZ7eUcRhrI-qwG52d40N-GwgM",
  authDomain: "hanimcilar-9f483.firebaseapp.com",
  projectId: "hanimcilar-9f483",
  storageBucket: "hanimcilar-9f483.appspot.com",
  messagingSenderId: "89038291387",
  appId: "1:89038291387:web:212927f2456bd6c70e049e",
  measurementId: "G-RLEY91VK0S"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);